<template>
  <div v-if="loaded" class="ongoing">
    <div v-if="!ongoing.currentVisits || ongoing.currentVisits.length === 0">
      <h5 class="zero-bottom w3-animate-opacity w3-center w3-padding">
        <span class="flow-margin-left font-weight-bold w3-margin-top w3-text-deep-orange">
            <i class="fa fa-bullhorn"></i>
            {{ $t('notOngoing') }}
        </span>
      </h5>
    </div>

    <div v-else-if="ongoing && ongoing.currentVisits" class="ongoing-content">

      <div class="photo-part">
        <div class="flow-padding-bottom zero-top flow-flex-col w3-right flex-align-end flex-1 flow-padding-left"
             v-if="imageBaseString">
          <img class="visitor-photo" :src="'data:image/png;base64,'+imageBaseString" alt="photo" draggable="false">
        </div>
      </div>

      <div class="middle-part">
        <h5 v-if="ongoing.currentVisits.length > 1" class="flow-main-color zero-bottom w3-animate-opacity w3-center">
          <i class="fa fa-users flow-green-active-color" aria-hidden="true" :title="$t('group')"
             style="display: inline-block">
          </i>
          <span class="font-weight-bold">
            <p style="display: inline-block; margin-left: 10px">{{
                $t("groupAppointment") + ": " + ongoing.currentVisits.length + " " + $t("persons")
              }}</p>
          </span><br>
          <span class="font-weight-normal">{{ ongoing.groupName }}
          </span>
        </h5>

        <h5 v-else class="flow-main-color zero-bottom w3-animate-opacity w3-center">
          <span class="font-weight-bold">
            <VisitorNameHeader :props-visitor="ongoing.currentVisits[0].visitor"></VisitorNameHeader>
          </span><br>
          <span class="font-weight-normal">
            ({{ ongoing.currentVisits[0].appointmentDateTime }}<span v-if="ongoing.currentVisits[0].domainTypeName"> - {{
              ongoing.currentVisits[0].domainTypeName
            }}</span>)
          </span>
          <span v-if="ongoing.currentVisits[0].visitTicket"> - <b>{{
              $t('ticket')
            }} :</b>  {{ ongoing.currentVisits[0].visitTicket }}</span>
          <br>
          <i class="fa fa-birthday-cake flow-green-active-color" aria-hidden="true" :title="$t('dob')">
          </i>
          <span class="flow-margin-left">{{
              ongoing.currentVisits[0].visitor.dob ? ongoing.currentVisits[0].visitor.dob : $t('notAvailable')
            }}</span>
        </h5>

        <!--Buttons-->
        <div>
          <button
            class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top w3-margin-right w3-red"
            @click="endVisit">
            {{ $t('endVisit') }}
          </button>

          <button
            class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top  flow-green-bg-active-color w3-text-white"
            @click="holdAppointment">
            {{ $t('endAndCallNext') }}
          </button>
        </div>
      </div>

      <!--  Special action buttons-->
      <div class="special-buttons">

        <div class="w3-margin-bottom">
          <div class="fa-stack cursor-pointer" :title="$t('recallVisitor')" @click.prevent="recallVisitor">
            <i class="fa fa-circle fa-stack-2x flow-blue-text"></i>
            <i class="fa fa-redo-alt fa-stack-1x fa-inverse"></i>
          </div>

          <div class="fa-stack  cursor-pointer" :title="$t('holdAppointment')" @click.prevent="holdAppointment">
            <i class="fa fa-circle fa-stack-2x flow-blue-text"></i>
            <i class="fa fa-user-clock fa-stack-1x fa-inverse"></i>
          </div>

          <div class="fa-stack  cursor-pointer" :title="$t('markNoShow')">
            <i class="fa fa-circle fa-stack-2x flow-blue-text"></i>
            <i class="fa fa-user-times fa-stack-1x fa-inverse"></i>
          </div>
        </div>

        <div>
          <div class="fa-stack  cursor-pointer" :title="$t('nextAppointment')">
            <i class="fa fa-circle fa-stack-2x flow-blue-text"></i>
            <i class="fa fa-calendar-alt fa-stack-1x fa-inverse"></i>
          </div>

          <div class="fa-stack  cursor-pointer" :title="$t('forwardTo')">
            <i class="fa fa-circle fa-stack-2x flow-blue-text"></i>
            <i class="fa fa-share fa-stack-1x fa-inverse"></i>
          </div>

          <div class="fa-stack  cursor-pointer" :title="$t('addComments')">
            <i class="fa fa-circle fa-stack-2x flow-blue-text"></i>
            <i class="fa fa-comment fa-stack-1x fa-inverse"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="w3-col w3-padding-large w3-center">
    <div>
      <h5 class="flow-main-color zero-bottom w3-animate-opacity w3-center">
        <span class="flow-margin-left font-weight-bold  w3-margin-top">
            {{ $t('loading') }}
        </span>
        <span class="zero">
          {{ $t('pleaseWait') }}
        </span>
      </h5>
    </div>
  </div>

</template>
<script>
import VisitorNameHeader from "../../common/VisitorNameHeader";

export default {
  name: "OngoingVisit",
  components: {VisitorNameHeader},
  props: ['ongoing', "endVisit", "holdAppointment", "recallVisitor"],
  data() {
    return {
      loaded: true,
      imageBaseString: null
    }
  },
  mounted() {
    this.getPhotoContent();
  },
  watch: {
    ongoing() {
      this.imageBaseString = null;
      this.getPhotoContent();
    }
  },
  methods: {
    getPhotoContent() {
      if (Object.keys(this.ongoing).length > 0) {
        if (this.ongoing.currentVisits.length === 1) {
          let hexString = this.ongoing.currentVisits[0].visitor.photoFile ? this.$helper.extractHexString(this.ongoing.currentVisits[0].visitor.photoFile) : '';
          this.imageBaseString = hexString ? Buffer.from(hexString, 'hex').toString('base64') : '';
        }
      }
    },
  }
}

</script>

<style scoped>
.ongoing {
  min-height: 152px;
}

.ongoing-content {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}

.ongoing .flow-btn {
  min-width: 200px;
}

.middle-part {
  padding: 0 10px;
}

.special-buttons div {
  font-size: 1.4em;
}

.special-buttons .fa-circle {
  text-shadow: 3px 1px 2px gray;
  color: var(--flow-green-other-color);
}

.special-buttons .fa-stack:hover .fa-circle {
  color: var(--flow-main-color);
}


@media screen and (max-width: 1048px) {

  .special-buttons div {
    font-size: 1.2em;
  }
}
</style>